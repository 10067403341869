import { crudRoutes, expeditionRoutes, generalRoutes } from "./routesConstants";

import userRoles from "../constants/userRoles";

import { Error404 } from "../pages/Error/Error404";
import { Error500 } from "../pages/Error/Error500";
import { ErrorsPage } from "../pages/Error/ErrorPage";
import CreateAwb from "../pages/CreateAwb";
import CreateBin from "../pages/CreateBin";
import ExpeditionScheduleWorkingPoints from "../pages/Expedition/ClientWorkingPoints";
import ExpeditionScheduleWorkingPointsOrganise from "../pages/Expedition/ClientWorkingPoints/ClientWorkingPointsOrganise";

const errorRoutes = [
  {
    id: "Error",
    path: "error/*",
    element: <ErrorsPage />,
  },
  {
    id: "Error500",
    path: "/error/500",
    element: <Error500 />,
  },
  {
    id: "Error404",
    path: "*",
    element: <Error404 />,
  },
];

const transport = [
  {
    id: userRoles.PREVIEW_USERS,
    path: `/${generalRoutes.createAwb}`,
    element: <CreateAwb />,
  },
  {
    id: userRoles.PREVIEW_USERS,
    path: `/${generalRoutes.createBin}`,
    element: <CreateBin />,
  },
];

const expedition = [
  {
    id: userRoles.SCHEDULE_WORKING_POINTS,
    path: `/${expeditionRoutes.clientWorkingPoints}`,
    element: <ExpeditionScheduleWorkingPoints />,
  },
  {
    id: userRoles.WORKING_POINTS_ORGANIZE,
    path: `/${expeditionRoutes.clientWorkingPoints}/${expeditionRoutes.organize}`,
    element: <ExpeditionScheduleWorkingPointsOrganise withUpdate />,
  },
  {
    id: userRoles.WORKING_POINTS_ORGANIZE,
    path: `/${expeditionRoutes.clientWorkingPoints}/${crudRoutes.create}`,
    element: <ExpeditionScheduleWorkingPointsOrganise />,
  },
];

function useAppRoutes() {
  return {
    errorRoutes,
    allRoutes: [...transport, ...expedition],
    transport: transport,
    expedition: expedition,
  };
}

export default useAppRoutes;
