export const initialMainRoute = "dashboard";

export const crudRoutes = {
  create: "creaza",
  edit: "edit",
};

export const generalRoutes = {
  createAwb: "creeaza-awb",
  createBin: "creeaza-bin",
};

export const expeditionRoutes = {
  routes: "rute",
  nodes: "noduri",
  workingPoints: "puncte-de-lucru",
  clientWorkingPoints: "puncte-de-lucru-client",
  organize: "organizeaza",
};
