import { createSlice } from "@reduxjs/toolkit";
import {
  ScheduleWorkingPointsDetailsInterface,
  ScheduleWorkingPointsInterface,
  ScheduleWorkingRoutesDetailsInterface,
  ScheduleWorkingRoutesInterface,
  WorkingRoutesNodesInterface,
} from "../interfaces/expedition.interface";

import {
  getScheduleWorkingPoints,
  getScheduleWorkingPointsDetails,
  getWorkingRoutes,
  getWorkingRoutesDetails,
  fetchGetClientsNodes,
} from "../api/expedition.api";

const initialExpeditionState: {
  scheduleWorkingPoints: ScheduleWorkingPointsInterface;
  scheduleWorkingPointsDetails: ScheduleWorkingPointsDetailsInterface;
  scheduleWorkingRoutes: ScheduleWorkingRoutesInterface;
  scheduleWorkingRoutesDetails: ScheduleWorkingRoutesDetailsInterface;
  workingRoutesNodes: WorkingRoutesNodesInterface;
} = {
  scheduleWorkingPoints: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  scheduleWorkingPointsDetails: {
    data: {
      days: [],
      pctLucru: null,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  scheduleWorkingRoutes: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  scheduleWorkingRoutesDetails: {
    data: {
      days: [],
      ruta: null,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
  workingRoutesNodes: {
    data: {
      current_page: 1,
      data: [],
      last_page: 1,
      perPage: 1,
      total: 1,
    },
    isLoading: false,
    isError: false,
    errorMessage: "",
  },
};

const expeditionReducer = createSlice({
  name: "expedition",
  initialState: initialExpeditionState,
  reducers: {
    clearScheduleWorkingPoints: (state) => {
      return {
        ...state,
        scheduleWorkingPoints: {
          ...initialExpeditionState.scheduleWorkingPoints,
        },
      };
    },
    clearScheduleWorkingRoutes: (state) => {
      return {
        ...state,
        scheduleWorkingRoutes: {
          ...initialExpeditionState.scheduleWorkingRoutes,
        },
      };
    },
    clearWorkingRoutesNodes: (state) => {
      return {
        ...state,
        workingRoutesNodes: {
          ...initialExpeditionState.workingRoutesNodes,
        },
      };
    },
    resetScheduleWorkingPointsDetails: (state) => {
      return {
        ...state,
        scheduleWorkingPointsDetails: {
          ...initialExpeditionState.scheduleWorkingPointsDetails,
        },
      };
    },
    resetScheduleWorkingRoutesDetails: (state) => {
      return {
        ...state,
        scheduleWorkingRoutesDetails: {
          ...initialExpeditionState.scheduleWorkingRoutesDetails,
        },
      };
    },
  },
  extraReducers: (builder) => {
    // get schedule working points start
    builder.addCase(getScheduleWorkingPoints.pending, (state) => {
      return {
        ...state,
        scheduleWorkingPoints: {
          ...initialExpeditionState.scheduleWorkingPoints,
          isLoading: true,
        },
      };
    });
    builder.addCase(getScheduleWorkingPoints.rejected, (state, action: any) => {
      return {
        ...state,
        scheduleWorkingPoints: {
          ...state.scheduleWorkingPoints,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(getScheduleWorkingPoints.fulfilled, (state, action) => {
      return {
        ...state,
        scheduleWorkingPoints: {
          ...state.scheduleWorkingPoints,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // get schedule working points end
    // get schedule working points details start
    // get  schedule working routes details start
    builder.addCase(getScheduleWorkingPointsDetails.pending, (state) => {
      return {
        ...state,
        scheduleWorkingPointsDetails: {
          ...initialExpeditionState.scheduleWorkingPointsDetails,
          isLoading: true,
        },
      };
    });
    builder.addCase(
      getScheduleWorkingPointsDetails.rejected,
      (state, action: any) => {
        return {
          ...state,
          scheduleWorkingPointsDetails: {
            ...state.scheduleWorkingPointsDetails,
            isLoading: false,
            errorMessage: action.payload.message,
            isError: true,
          },
        };
      },
    );
    builder.addCase(
      getScheduleWorkingPointsDetails.fulfilled,
      (state, action) => {
        return {
          ...state,
          scheduleWorkingPointsDetails: {
            ...state.scheduleWorkingPointsDetails,
            isLoading: false,
            data: action.payload,
          },
        };
      },
    );
    // end get schedule working points details end
    // get schedule working routes start
    builder.addCase(getWorkingRoutes.pending, (state) => {
      return {
        ...state,
        scheduleWorkingRoutes: {
          ...initialExpeditionState.scheduleWorkingRoutes,
          isLoading: true,
        },
      };
    });
    builder.addCase(getWorkingRoutes.rejected, (state, action: any) => {
      return {
        ...state,
        scheduleWorkingRoutes: {
          ...state.scheduleWorkingRoutes,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });

    builder.addCase(getWorkingRoutes.fulfilled, (state, action) => {
      return {
        ...state,
        scheduleWorkingRoutes: {
          ...state.scheduleWorkingRoutes,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // get schedule working routes end
    // get  schedule working routes details start
    builder.addCase(getWorkingRoutesDetails.pending, (state) => {
      return {
        ...state,
        scheduleWorkingRoutesDetails: {
          ...initialExpeditionState.scheduleWorkingRoutesDetails,
          isLoading: true,
        },
      };
    });
    builder.addCase(getWorkingRoutesDetails.rejected, (state, action: any) => {
      return {
        ...state,
        scheduleWorkingRoutesDetails: {
          ...state.scheduleWorkingRoutesDetails,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(getWorkingRoutesDetails.fulfilled, (state, action) => {
      return {
        ...state,
        scheduleWorkingRoutesDetails: {
          ...state.scheduleWorkingRoutesDetails,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    //  get  schedule working routes details end
    // get working routes nodes start
    builder.addCase(fetchGetClientsNodes.pending, (state) => {
      return {
        ...state,
        workingRoutesNodes: {
          ...initialExpeditionState.workingRoutesNodes,
          isLoading: true,
        },
      };
    });
    builder.addCase(fetchGetClientsNodes.rejected, (state, action: any) => {
      return {
        ...state,
        workingRoutesNodes: {
          ...state.workingRoutesNodes,
          isLoading: false,
          errorMessage: action.payload.message,
          isError: true,
        },
      };
    });
    builder.addCase(fetchGetClientsNodes.fulfilled, (state, action) => {
      return {
        ...state,
        workingRoutesNodes: {
          ...state.workingRoutesNodes,
          isLoading: false,
          data: action.payload,
        },
      };
    });
    // get working routes nodes end
  },
});

export const {
  clearScheduleWorkingPoints,
  clearScheduleWorkingRoutes,
  clearWorkingRoutesNodes,
  resetScheduleWorkingPointsDetails,
  resetScheduleWorkingRoutesDetails,
} = expeditionReducer.actions;

export default expeditionReducer.reducer;
